import { useState, useEffect } from 'react'

export type DarkModeType = 'auto' | 'on' | 'off'

export const useThemeDetector = (): boolean => {
  const prefersDark = window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)') : undefined
  const [darkMode, setDarkMode] = useState<DarkModeType>('off')
  const getCurrentTheme = (matches: boolean): boolean => ((darkMode === 'on' || darkMode === 'auto') && matches)
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme(prefersDark?.matches ?? false))
  const mqListener = (e: any): void => {
    setIsDarkTheme(getCurrentTheme(e.matches))
  }

  useEffect(() => {
    prefersDark?.addListener(mqListener)
    return () => prefersDark?.removeListener(mqListener)
  }, [])

  useEffect(() => {
    window.parent.postMessage('query-dark-mode', '*')
    window.addEventListener(
      'message',
      (ev: MessageEvent<{
        type: string
        darkMode: DarkModeType
      }>) => {
        if (ev.data.type === 'amwal-order-id') {
          if (ev.data.darkMode) {
            setDarkMode(ev.data.darkMode)
          }
        }
      }
    )
  }, [])

  useEffect(() => {
    setIsDarkTheme(getCurrentTheme(prefersDark?.matches ?? false))
  }, [darkMode])
  return isDarkTheme ?? false
}
